import { DownloadOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Row, Typography } from "antd";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Content } = Layout;

const catalogos = [
    { titulo: "Catálogo de Tintas", descricao: "Linha completa de tintas para sua casa ou projeto comercial.", arquivo: "CatalogoTintas.pdf", link: "/cat_tintas", imagem: "/produtos/duracryl/DURACRYL_PREMIUM_ACETINADO_LAVAVEL.jpg" },
    { titulo: "Catálogo de Argamassas", descricao: "Soluções profissionais para pintura automotiva.", arquivo: "CatalogoArgamassas.pdf", link: "/cat_argamassas", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp" },
    { titulo: "Catálogo de Impermeabilizantes", descricao: "Produtos para proteção contra umidade e infiltrações.", arquivo: "CatalogoImpermeabilizantes.pdf", link: "/cat_imperm", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp" },
    { titulo: "Catálogo de Churrasqueiras", descricao: "Acabamentos texturizados para paredes internas e externas.", arquivo: "CatalogoChurrasqueiras.pdf", link: "/cat_churras", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp" },
    { titulo: "Catálogo Linha Premium", descricao: "Nossa linha de produtos premium com alta durabilidade.", arquivo: "CatalogoLinhaPremium.pdf", link: "/cat_premium", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp" },
    { titulo: "Catálogo Bella Clean", descricao: "Massas corridas, acrílicas e produtos complementares.", arquivo: "CatalogoBellaClean.pdf", link: "/cat_bela", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp" },
];


export default function CatalogosGeral() {
    return (
        <div style={{}}>
            <Layout>
                <Content className="" style={{ paddingTop: '20px', backgroundColor: '#fff' }}>
                    <div className="container">
                        <div style={{
                            flexDirection: 'column',
                            justifyContent: 'left',
                            alignItems: 'left',
                            paddingBottom: '1rem',
                        }}>
                            <Typography style={{ fontSize: '24px' }}>Catálogos Durafix</Typography>
                            <Typography>Sua solução completa para tintas e produtos de acabamento de alta qualidade.</Typography>
                        </div>

                        <Row gutter={[16, 16]} justify="start">
                            {catalogos.map((item, index) => (
                                <Col xs={24} sm={12} md={8} lg={8} key={index}>
                                    <Card
                                        hoverable
                                        style={{ textAlign: 'center', borderRadius: '10px', backgroundColor: '#f0eaeab3' }}
                                    >
                                        <img
                                            src={item.imagem}
                                            style={{
                                                width: "120px",
                                                height: "120px",
                                                objectFit: "cover", // Mantém a imagem bem ajustada dentro do círculo
                                                display: "block",
                                                margin: "0 auto",
                                                borderRadius: "50%", // Torna a imagem completamente circular
                                            }}
                                            className="produto-imagem"
                                        />


                                        {/* <FileTextOutlined style={{ fontSize: '40px', color: '#1890ff' }} /> */}
                                        <h3>{item.titulo}</h3>
                                        <p>{item.descricao}</p>

                                        {/* Link para outra página ao clicar em Visualizar */}
                                        <Link to={item.link} style={{ textDecoration: 'none' }}>
                                            <Button type="primary" style={{ marginRight: '10px' }}>Visualizar</Button>
                                        </Link>

                                        {/* Link para baixar o PDF da pasta public/pdf */}
                                        <a href={`/pdf/${item.arquivo}`} download={item.arquivo} type="application/pdf">
                                            <Button icon={<DownloadOutlined />}>Baixar</Button>
                                        </a>


                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Content>
            </Layout>

        </div>
    )
}