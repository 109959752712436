import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import TintasPage from "./paginas/tintas";
import MenuComponent from "./components/menuComponent";
import ArgamassasPage from "./paginas/argamassas";
import HomePage from "./paginas/home";
import RodapeComponent from "./components/rodapeComponent";
import CatalogoTintasPage from "./paginas/catalogos/tintas";
import CatalogoArgamassasPage from "./paginas/catalogos/argamassa";
import CatalogoImpermeabilizantesPage from "./paginas/catalogos/impermeabilizantes";
import CatalogoChurrasqueirasPage from "./paginas/catalogos/churrasqueiras";
import CatalogoLinhaPremiumPage from "./paginas/catalogos/linhaPremium";
import CatalogoBelaCleanPage from "./paginas/catalogos/bellaClean";
import CatalogosGeral from "./paginas/catalogos/catalogosGeral";
import ImpermeabilizantesPage from "./paginas/impermeabilizantes";

const App = () => {
  return (
    <BrowserRouter>
      <MenuComponent /> {/* Certifique-se de que o MenuNav está dentro do BrowserRouter */}
      <div style={{ paddingTop: '70px' }}></div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tintas" element={<TintasPage />} />
        <Route path="/argamassas" element={<ArgamassasPage />} />
        <Route path="/impermeabilizantes" element={<ImpermeabilizantesPage />} />
        <Route path="/catalogos" element={<CatalogosGeral />} />
        <Route path="/cat_tintas" element={<CatalogoTintasPage />} />
        <Route path="/cat_argamassas" element={<CatalogoArgamassasPage />} />
        <Route path="/cat_imperm" element={<CatalogoImpermeabilizantesPage />} />
        <Route path="/cat_churras" element={<CatalogoChurrasqueirasPage />} />
        <Route path="/cat_premium" element={<CatalogoLinhaPremiumPage />} />
        <Route path="/cat_bela" element={<CatalogoBelaCleanPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <RodapeComponent/>
    </BrowserRouter>
  );
};

export default App;
