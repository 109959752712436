"use client"

import { Typography, Space, Row, Col } from "antd"
import { WhatsAppOutlined, InstagramOutlined, EnvironmentOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"

const { Text } = Typography

const RodapeComponent = () => {
  const [isMobile, setIsMobile] = useState(false)

  // Função para verificar se a tela é mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Verificar inicialmente
    checkIfMobile()

    // Adicionar listener para redimensionamento
    window.addEventListener("resize", checkIfMobile)

    // Limpar listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", checkIfMobile)
    }
  }, [])

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        height: isMobile ? "auto" : "60px", // Altura automática para mobile
        backgroundColor: "#0047AB", // Cor azul DURAFIX
        color: "white",
        padding: isMobile ? "10px 15px" : "0 20px",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
      }}
    >
      {isMobile ? (
        // Layout para dispositivos móveis
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href="https://wa.me/5586994321635"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center" }}
              >
                <WhatsAppOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
                <Text style={{ color: "white", fontSize: "12px" }}>(86) 99432-1635</Text>
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href="https://www.instagram.com/durafixoficial"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center" }}
              >
                <InstagramOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
                <Text style={{ color: "white", fontSize: "12px" }}>@durafixoficial</Text>
              </a>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <EnvironmentOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
            <Text style={{ color: "white", fontSize: "12px" }}>Rua C, Nº 148 - Distrito Industrial</Text>
          </div>

          {/* Centralizando o texto dos direitos reservados */}
          <div style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
            <Text style={{ color: "white", fontSize: "12px" }}>2025 | Todos os direitos reservados</Text>
          </div>
        </div>
      ) : (
        // Layout para desktop
        <Row gutter={24} style={{ width: "100%" }}>
          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
            <div className="contatos" style={{ display: "flex", alignItems: "center" }}>
              <Text strong style={{ color: "white", fontSize: "14px", marginRight: "15px" }}>
                Contatos:
              </Text>
              <Space size="large">
                {/* Link para WhatsApp */}
                <a
                  href="https://wa.me/5586994321635"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center" }}
                >
                  <WhatsAppOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
                  <Text style={{ color: "white", fontSize: "12px" }}>(86) 99432-1635</Text>
                </a>

                {/* Link para Instagram */}
                <a
                  href="https://www.instagram.com/durafixoficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center" }}
                >
                  <InstagramOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
                  <Text style={{ color: "white", fontSize: "12px" }}>@durafixoficial</Text>
                </a>
              </Space>

            </div>
          </Col>

          <Col span={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "white", fontSize: "12px" }}>2025 | Todos os direitos reservados</Text>
            {/* <div>
              <img
                src='/logo.png'
                style={{
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                  height: '50px'
                }}
                className="carousel-image produto-imagem"
                alt="Banner 3"
              />
            </div> */}
          </Col>

          <Col span={8} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <div className="endereco" style={{ display: "flex", alignItems: "center" }}>
              <Text strong style={{ color: "white", fontSize: "14px", marginRight: "10px" }}>
                Endereço:
              </Text>
              <div style={{ display: "flex", alignItems: "center" }}>
                <EnvironmentOutlined style={{ marginRight: "5px", fontSize: "14px" }} />
                <Text style={{ color: "white", fontSize: "12px" }}>Rua C, Nº 148 - Distrito Industrial</Text>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default RodapeComponent

