import { Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'; // Importando o ícone de download
import Title from 'antd/es/typography/Title';

export default function CatalogoBelaCleanPage() {
    return (
        <div className="container" style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0px 20px 80px 20px', // Padding top 60px (para ficar abaixo do menu) e bottom 80px (para ficar acima do rodapé)
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                {/* Botão de Download */}
                <div style={{ paddingBottom: '20px', textAlign: 'left', width: '100%' }}>
                    {/* Título com fonte maior */}
                    <Title level={1} style={{ fontSize: '36px',  marginBottom: '30px' }}>
                        Catálogo de BelaClean
                    </Title>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />} // Ícone de download
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/pdf/CatalogoBellaClean.pdf';
                            link.download = 'CatalogoBellaClean.pdf';
                            link.target = '_blank';
                            link.click();
                        }}
                        title='Baixar Catalogo(Pdf)'
                        style={{
                            backgroundColor: '#fff',
                            borderColor: '#0047AB',
                            display: 'inline-flex', // Garante que o ícone e o botão fiquem na mesma linha
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            color: '#000'
                        }}
                    >download</Button>
                </div>

                {/* Imagem 1 */}
                <div style={{ marginBottom: '30px', textAlign: 'center', width: '100%' }}>
                    <img
                        src="/catalogos_imagens/bellaClean/CatalogoBellaClean_00.png"
                        alt="Catálogo de Tintas - Página 1"
                        style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    />
                </div>

                {/* Imagem 2 */}
                <div style={{ marginBottom: '30px', textAlign: 'center', width: '100%' }}>
                    <img
                        src="/catalogos_imagens/bellaClean/CatalogoBellaClean_01.png"
                        alt="Catálogo de Tintas - Página 2"
                        style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
