import React, { useEffect, useState } from "react";
import { Layout, Menu, Card, Row, Col, Button, Modal, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";


export default function MenuComponent() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [drawerVisible, setDrawerVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return (
        <>
            <Header className=""
                style={{
                    backgroundColor: "#0647bd",
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: isMobile ? "space-between" : "center", // Alinha items entre espaço para mobile
                    alignItems: "center",
                    padding: "0 20px",
                }}>
                {isMobile ? (
                    <>


                        {/* Botão de Menu à esquerda */}
                        <Button
                            type="text"
                            icon={<MenuOutlined style={{ color: "#fff", fontSize: 24 }} />}
                            onClick={() => setDrawerVisible(true)}
                        />
                        {/* Logo à direita */}
                        <img
                            src="/logo.png"
                            alt="Logo"
                            style={{ height: "40px", order: 2, paddingRight: '30px' }} // A logo ficará à direita
                        />
                        <Drawer
                            title="Menu"
                            placement="left"
                            onClose={() => setDrawerVisible(false)}
                            open={drawerVisible} // Correto
                        >
                            <Menu mode="vertical" style={{ borderRight: 0 }}>
                                <Menu.Item key="1">
                                    <Link
                                        to="/"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Home
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Link
                                        to="/tintas"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Tintas
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <Link
                                        to="/argamassas"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Argamassas
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <Link
                                        to="/impermeabilizantes"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Impermeabilizantesssssssss
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="5">
                                    <Link
                                        to="/churrasqueiras"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Churrasqueiras
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="6">
                                    <Link
                                        to="/videos"
                                        style={{ color: '#000', textDecoration: 'none' }}
                                        onClick={() => setDrawerVisible(false)} // Fecha o Drawer ao clicar
                                    >
                                        Vídeos
                                    </Link>
                                </Menu.Item>
                            </Menu>

                            <Menu mode="horizontal" style={{ backgroundColor: "#0647bd" }}>
                                <Menu.SubMenu
                                    key="sub1"
                                    title={<span style={{ color: "#fff", }}>Catálogos</span>}
                                >
                                    <Menu.Item key="7">
                                        <Link to="/cat_tintas" className="" onClick={() => setDrawerVisible(false)} >Tintas</Link>
                                    </Menu.Item>
                                    <Menu.Item key="10">
                                        <Link to="/cat_argamassas" style={{ color: '#000' }} className="" onClick={() => setDrawerVisible(false)} >Argamassas</Link>
                                    </Menu.Item>
                                    <Menu.Item key="8">
                                        <Link to="/cat_imperm" style={{ color: '#000' }} className="" onClick={() => setDrawerVisible(false)} >Impermeabilizantes</Link>
                                    </Menu.Item>
                                    <Menu.Item key="9">
                                        <Link to="/cat_churras" style={{ color: '#000' }} className="" onClick={() => setDrawerVisible(false)} >Churrasqueiras</Link>
                                    </Menu.Item>
                                    <Menu.Item key="11">
                                        <Link to="/cat_premium" style={{ color: '#000' }} className="" onClick={() => setDrawerVisible(false)} >Linha Premium</Link>
                                    </Menu.Item>
                                    <Menu.Item key="12">
                                        <Link to="/cat_bela" style={{ color: '#000' }} className="" onClick={() => setDrawerVisible(false)} >Catálogo BellaClean</Link>
                                    </Menu.Item>
                                    {/* <Menu.Item key="12">
                                            <a href="/pdf/CatalogoBellaClean.pdf" download="CatalogoBellaClean.pdf">CatálogoBella Clean</a>
                                        </Menu.Item> */}
                                </Menu.SubMenu>
                            </Menu>
                        </Drawer>
                    </>
                ) : (
                    // Menu desktop padrão
                    <>
                        <div className="logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px", background: "transparent" }}>
                            <img src="/logo.png" alt="DURAFIX Logo" style={{ height: "40px" }} />
                        </div>
                        <div className="menu-container" style={{ backgroundColor: '#0647bd', justifyContent: "left" }}>
                            <Menu theme="light" mode="horizontal" defaultSelectedKeys={["1"]} className="menu" style={{ backgroundColor: "#0647bd", borderBottom: "none" }}>
                                <Menu.Item key="1" style={{ color: '#fff' }} className="menu-item">
                                    <Link to="/" >Home</Link>
                                </Menu.Item>
                                <Menu.Item key="2" style={{ color: '#fff' }} className="menu-item">
                                    <Link to="/tintas" >Tintas</Link>
                                </Menu.Item>
                                <Menu.Item key="3" style={{ color: '#fff' }} className="menu-item">
                                    <Link to="/argamassas" style={{ color: '#fff', textDecoration: 'none' }}>Argamassas</Link>
                                </Menu.Item>
                                <Menu.Item key="4" style={{ color: '#fff' }} className="menu-item">
                                    <Link
                                        to="/impermeabilizantes"
                                    >
                                        Impermeabilizantes
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="5" style={{ color: '#fff' }} className="menu-item">Churrasqueiras</Menu.Item>
                                <Menu.Item key="6" style={{ color: '#fff' }} className="menu-item">Vídeos</Menu.Item>
                                {/* <Menu.Item key="7" style={{ color: '#fff' }}>Catálogos</Menu.Item> */}
                            </Menu>

                            <Menu mode="horizontal" style={{ backgroundColor: "#0647bd" }} triggerSubMenuAction="hover">
                                <Menu.SubMenu
                                    key="sub1"
                                    title={<span style={{ color: "#fff" }} className="menu-item">Catálogos</span>}
                                >
                                    <Menu.Item key="7">
                                        <Link to="/cat_tintas" className="menu-item-black">Tintas</Link>
                                    </Menu.Item>
                                    <Menu.Item key="10">
                                        <Link to="/cat_argamassas" className="menu-item-black">Argamassas</Link>
                                    </Menu.Item>
                                    <Menu.Item key="8">
                                        <Link to="/cat_imperm" className="menu-item-black">Impermeabilizantes</Link>
                                    </Menu.Item>
                                    <Menu.Item key="9">
                                        <Link to="/cat_churras" className="menu-item-black">Churrasqueiras</Link>
                                    </Menu.Item>
                                    <Menu.Item key="11">
                                        <Link to="/cat_premium" className="menu-item-black">Linha Premium</Link>
                                    </Menu.Item>
                                    <Menu.Item key="12">
                                        <Link to="/cat_bela" className="menu-item-black">Catálogo BellaClean</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                            </Menu>

                        </div>
                    </>
                )}
            </Header>
        </>
    );
}