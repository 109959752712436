import { Button, Card, Col, Layout, Modal, Row } from "antd";
import Typography from "antd/es/typography/Typography";
import { useEffect, useState } from "react";

const { Content } = Layout;

type Produto = {
    nome: string;
    descricao: string;
    imagem: string;
    lamina?: string; // A propriedade 'lamina' agora é opcional
};

const categorias = [
    {
        titulo: "Rejuntes",
        produtos: [
            { nome: "Rejunte Premium", descricao: "Rejunte Premium", imagem: "/produtos/rejuntes/REJUNTE_PREMIUM.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Rejunte Piscina", descricao: "Rejunte Piscina", imagem: "/produtos/rejuntes/REJUNTE_PISCINA.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Rejunte Porcelanatos", descricao: "Rejunte Piscina", imagem: "/produtos/rejuntes/Rejunte_porcelanatos.png", lamina: '/laminas/texturas_especiais/Rejunte_porcelanatos.png' },
            { nome: "Rejunte Cerâmicas", descricao: "Rejunte Cerâmicas", imagem: "/produtos/rejuntes/REJUNTE_CERAMICAS.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
        ],
    },
    {
        titulo: "Argamassas Colantes",
        produtos: [
            { nome: "Porcelanato Externo", descricao: "Porcelanato", imagem: "/produtos/argamassas_colantes/15KG_ARGAMASSA_PORCELANATO_EXTERNO.webp", lamina: '/produtos/argamassas_colantes/sem_imagem.png' },
            { nome: "Cerâmica Externa", descricao: "Cerâmica Externa", imagem: "/produtos/argamassas_colantes/ARGAMASSA_CERAMICA_EXTERNA.webp", lamina: '/produtos/argamassas_colantes/sem_imagem.png' },
            { nome: "Cerâmica Interna AC I", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/CERAMICA-INTERNA.png", lamina: '/produtos/argamassas_colantes/CERAMICA-INTERNA.png' },
            { nome: "Banheiros e Cozinhas AC I", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/BANHEIROS_COZINHAS.png", lamina: '/produtos/argamassas_colantes/BANHEIROS_COZINHAS.png' },
            { nome: "Piso Sobre Piso AC III", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/MOCKUP-ARGAMASSA-FRENTE.png", lamina: '/produtos/argamassas_colantes/MOCKUP-ARGAMASSA-FRENTE.png' },
            { nome: "Grandes Formatos IIIE", descricao: "Grandes Formatos IIIE", imagem: "/produtos/argamassas_colantes/GRANDES_FORMATOS_AC_III_E.webp", lamina: '/laminas/argamassas_colantes/sem_imagem.png' },
            { nome: "Premium AC IIIE", descricao: "Premium AC IIIE", imagem: "/produtos/argamassas_colantes/PREMIUM_AC_III_E.webp", lamina: '/produtos/argamassas_colantes/sem_imagem.png' },
            { nome: "Porcelanato Interno AC II", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/PORCELANATO-INTERNO.png", lamina: '/produtos/argamassas_colantes/PORCELANATO-INTERNO.png' },
            { nome: "Piscina AC II", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/PISCINA.png", lamina: '/produtos/argamassas_colantes/PISCINA.png' },
            { nome: "Branco AC III", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/BRANCA.png", lamina: '/produtos/argamassas_colantes/BRANCA.png' },
            { nome: "Reboco Pronto 4 em 1", descricao: "Rejunte Piscina", imagem: "/produtos/argamassas_colantes/REBOCO_PRONTO.webp", lamina: '/produtos/argamassas_colantes/REBOCO_PRONTO.webp' },
        ],
    },
    {
        titulo: "Argamassas Especiais",
        produtos: [
            { nome: "Autonivelante Rápida", descricao: "Autonivelante Rápida", imagem: "/produtos/argamassas_especiais/AUTONIVELANTE_RAPIDA.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Chapisco Rápido", descricao: "Chapisco Rápido", imagem: "/produtos/argamassas_especiais/CHAPISCO_ROLADO.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Cimento Branco", descricao: "Cimento Branco", imagem: "/produtos/argamassas_especiais/CIMENTO_BRANCO.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Gesso", descricao: "Gesso", imagem: "/produtos/argamassas_especiais/GESSO.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Multimasas Tipo I", descricao: "Multimasas Tipo I", imagem: "/produtos/argamassas_especiais/MULTIMASSA_TP_1.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Multimassa Tipo II", descricao: "Multimassa Tipo II", imagem: "/produtos/argamassas_especiais/MULTIMASSA_TP_2.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Reboco Pronto", descricao: "Reboco Pronto", imagem: "/produtos/argamassas_especiais/REBOSCO_PRONTO.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Super Grauth", descricao: "Super Grauth", imagem: "/produtos/argamassas_especiais/SUPER_GRAUTH.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
        ],
    },
    {
        titulo: "Argamassas Impermeabilizantes",
        produtos: [
            { nome: "Duratop Flex", descricao: "Duratop Flex", imagem: "/produtos/argamassas_impermeabilizantes/DURATOP_FLEX.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Duratop Flex Rodapé", descricao: "Duratop Flex Rodapé", imagem: "/produtos/argamassas_impermeabilizantes/DURATOP_FLEX_RODAPE.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Duratop Semi Flexível", descricao: "Duratop Semi Flexível", imagem: "/produtos/argamassas_impermeabilizantes/DURATOP_SEMI_FLEXIVEL.webp", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
        ],
    },
   
]; 

export default function ArgamassasPage() {
    const [modalVisible, setModalVisible] = useState(false);
    const [imagemModal, setImagemModal] = useState("");
    const [nomeProduto, setProdutoNome] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [modalWidth, setModalWidth] = useState("50%");

    // Função para abrir o modal com a imagem
    const handleOpenModal = (imagem: string | undefined, nome: string) => {
        setImagemModal(""); // Limpa a imagem anterior
        setTimeout(() => {
            if (imagem) setImagemModal(imagem);
            setProdutoNome(nome);
            setModalVisible(true);
        }, 100); 
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleDownload = () => {
        if (!imagemModal) return; // Evita erro caso não tenha imagem carregada
        const link = document.createElement("a");
        link.href = imagemModal;
        link.download = imagemModal.split("/").pop() || "imagem.png"; // Nome do arquivo é o nome da imagem
        link.click();
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalWidth("99%"); // Mobile
            } else {
                setModalWidth("50%"); // Desktop
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call once to set initial width

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <Content className="content">
                <div className="container">
                    {categorias.map((categoria, index) => (
                        <div key={index} className="categoria">
                            <h2
                                className="categoria-titulo"
                                style={{
                                    backgroundColor: "#0647bd",
                                    color: "#fff",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "left",
                                    margin: 0,
                                    paddingLeft: '20px'
                                }}
                            >
                                {categoria.titulo}
                            </h2>
                            <Row gutter={[16, 16]}>
                                {categoria.produtos.map((produto: Produto, i) => (
                                    <Col key={i} xs={24} sm={12} md={6} lg={6}>
                                        <Card style={{ textAlign: "center", padding: "15px" }}>
                                            <img
                                                alt={produto.nome}
                                                src={produto.imagem}
                                                style={{
                                                    width: "120px",
                                                    height: "160px",
                                                    objectFit: "contain",
                                                    display: "block",
                                                    margin: "0 auto",
                                                }}
                                                className="produto-imagem"
                                            />
                                            <h3 style={{ fontSize: "14px", marginTop: "10px", minHeight: "40px" }}>
                                                {produto.nome}
                                            </h3>
                                            <Button
                                                type="primary"
                                                style={{
                                                    backgroundColor: "#0647bd",
                                                    borderColor: "#0647bd",
                                                    color: "#fff",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                }}
                                                onClick={() => handleOpenModal(produto.lamina, produto.nome)}
                                            >
                                                SAIBA MAIS
                                            </Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </div>
            </Content>

            <Modal
                visible={modalVisible}
                footer={null} // Remover footer para não sobrepor o botão
                onCancel={handleCloseModal}
                width="90%"
                style={{ maxWidth: modalWidth }} // Usando o valor de modalWidth
                title={
                    <Row style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            key="download"
                            onClick={handleDownload}
                            style={{ width: '150px', marginBottom: '10px' }} // Tamanho fixo de 150px
                        >
                            Baixar Imagem
                        </Button>
                        <Typography style={{ marginLeft: '20px', marginBottom: '10px', fontSize: '20px' }}>
                            {nomeProduto}
                        </Typography>
                    </Row>
                }
            >
                <img src={imagemModal} alt="Lâmina" style={{ width: '100%', height: 'auto' }} />
            </Modal>
        </Layout>
    );
};
