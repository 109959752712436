import { Button, Card, Col, Layout, Modal, Row } from "antd";
import Typography from "antd/es/typography/Typography";
import { useEffect, useState } from "react";

const { Content } = Layout;

type Produto = {
    nome: string;
    descricao: string;
    imagem: string;
    lamina?: string; // A propriedade 'lamina' agora é opcional
};

const categorias = [
    {
        titulo: "Linha Duracryl",
        produtos: [
            { nome: "Duracryl Premium Acetinada Lavável", descricao: "Descrição do produto", imagem: "/produtos/duracryl/DURACRYL_PREMIUM_ACETINADO_LAVAVEL.jpg", lamina: '/laminas/duracryl/LAMINA-ACETINADO-LAVAVEL-PREMIUM.webp' },
            { nome: "Duracryl Premium Semi Brilho", descricao: "Descrição do produto", imagem: "/produtos/duracryl/DURACRYL_PREMIUM_SEMI_BRILHO.jpg", lamina: '/laminas/duracryl/LAMINA_SEMI-BRILHO-PREMIUM.webp' },
            { nome: "Duracryl Premium Fosco", descricao: "Descrição do produto", imagem: "/produtos/duracryl/DURACRYL-PREMIUM-FOSCO-AVELUDADO.jpg", lamina: '/laminas/duracryl/LAMINA-DURACRYL-PREMIUM-FOSCO-AVELUDADO.jpg' },
            { nome: "Duracryl Fosco Standard", descricao: "Descrição do produto", imagem: "/produtos/duracryl/sem_imagem.png", lamina: '/laminas/duracryl/sem_imagem.png' },
            { nome: "Duracryl Standard Semi Brilho", descricao: "Descrição do produto", imagem: "/produtos/duracryl/DURACRYL-STANDARD-SEMI-BRILHO.jpg", lamina: '/laminas/duracryl/LAMINA-STANDARD-SEMI-BRILHO.webp' },
            { nome: "Duracryl Econômica", descricao: "Descrição do produto", imagem: "/produtos/duracryl/DURACRYL-ECONOMICA.jpg", lamina: '/laminas/duracryl/LAMINA-DURACRYL-ECONOMICA.webp' },
            { nome: "Borracha Líquida", descricao: "Descrição do produto", imagem: "/produtos/duracryl/BORRACHA-LIQUIDA.jpg", lamina: '/laminas/duracryl/sem_imagem.png' },
            { nome: "Tinta Emborrachada", descricao: "Descrição do produto", imagem: "/produtos/duracryl/cartela-pintura-emborrachada.jpg", lamina: '/laminas/duracryl/lamina-cartela-pintura-emborrachada.jpg' },
        ],
    },
    {
        titulo: "Texturas",
        produtos: [
            { nome: "Textura Lisa", descricao: "Descrição do produto", imagem: "/produtos/texturas/TEXTURA-LISA.jpg", lamina: '/laminas/texturas/LAMINA-TEXTURA.jpg' },
            { nome: "Textura Especial", descricao: "Descrição do produto", imagem: "/produtos/texturas/TEXTURA-RUSTICA.jpg", lamina: '/laminas/texturas/LAMINA-TEXTURA.jpg' },
            { nome: "Textura Rústica", descricao: "Descrição do produto", imagem: "/produtos/texturas/TEXTURA-ESPECIAL.jpg", lamina: '/laminas/texturas/LAMINA-TEXTURA.jpg' },
        ],
    },
    {
        titulo: "Texturas Especiais",
        produtos: [
            { nome: "Textura Pietra", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/PIETRA.jpg", lamina: '/laminas/texturas_especiais/LAMINA-PIETRA.jpg' },
            { nome: "Textura Aço Corten", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/ACO-CORTEN.JPG", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
            { nome: "Textura Granulatto", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/TEXTURA-GRANULATTO.jpg", lamina: '/laminas/texturas_especiais/LAMINA-GRANULATTO.webp' },
            { nome: "Textura Perolizado", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/TEXTURA-PEROLIZADA.jpg", lamina: '/laminas/texturas_especiais/LAMINA-TEXTURA-PEROLIZADA.webp' },
            { nome: "Textura Efeito Marmorizado", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/TEXTURA-EFEITO-MARMORIZADA.jpg", lamina: '/laminas/texturas_especiais/LAMINA-TEXTURA-EFEITO-MARMORIZADA.webp' },
            { nome: "Textura Efeito Cimento Queimado", descricao: "Descrição do produto", imagem: "/produtos/texturas_especiais/TEXTURA-CIMENTO-QUEIMADO.jpg", lamina: '/laminas/texturas_especiais/sem_imagem.png' },
        ],
    },
    {
        titulo: "Vernizes",
        produtos: [
            { nome: "Verniz Super Filtro Solar", descricao: "Verniz Super Filtro Solar", imagem: "/produtos/vernizes/FILTRO_SOLAR.webp", lamina: '/laminas/vernizes/LAMINA-FILTRO-SOLAR.webp' },
            { nome: "Verniz Copal", descricao: "Verniz Copal", imagem: "/produtos/vernizes/VERNIZ-COPAL.webp", lamina: '/laminas/vernizes/LAMINA-VERNIZ-COPAL.webp' },
        ],
    },
    {
        titulo: "Complementos",
        produtos: [
            { nome: "Massa Corrida Premium", descricao: "Massa Corrida Premium", imagem: "/produtos/complementos/MASSA-CORRIDA-PREMIUM.webp", lamina: '/laminas/complementos/sem_imagem.png' },
            { nome: "Massa Corrida", descricao: "Massa Corrida", imagem: "/produtos/complementos/MASSA-CORRIDA.webp", lamina: '/laminas/complementos/sem_imagem.png' },
            { nome: "Durapiso", descricao: "Durapiso", imagem: "/produtos/complementos/DURAPISO.webp", lamina: '/laminas/complementos/LAMINA-DURAPISO.webp' },
            { nome: "Selador Acrílico", descricao: "Selador Acrílico", imagem: "/produtos/complementos/SELADOR-ACRILICO.webp", lamina: '/laminas/complementos/sem_imagem.png' },
            { nome: "Duragesso", descricao: "Duragesso", imagem: "/produtos/complementos/DURAGESSO.webp", lamina: '/laminas/complementos/sem_imagem.png' },
            { nome: "Durabrilho", descricao: "Durabrilho", imagem: "/produtos/complementos/DURABRILHO.webp", lamina: '/laminas/complementos/sem_imagem.png' },
            { nome: "Massa Acrílíca", descricao: "Massa Acrílíca", imagem: "/produtos/complementos/MASSA-ACRILICA.webp", lamina: '/laminas/complementos/sem_imagem.png' },
        ],
    },
    {
        titulo: "Esmaltes",
        produtos: [
            { nome: "Esmalte Base Água", descricao: "Esmalte Base Água", imagem: "/produtos/esmaltes/esmalte-base-agua.webp", lamina: '/laminas/esmaltes/lamina-esmalte-base-agua.webp' },
            { nome: "Esmalte Sintético MultiUso", descricao: "Esmalte Sintético MultiUso Profissional", imagem: "/produtos/esmaltes/esmalte-sintetico-multiuso.webp", lamina: '/laminas/esmaltes/lamina-esmalte-sintetico-multiuso.webp' },
            { nome: "Esmalte Secagem Rápida", descricao: "Esmalte Secagem Rápida", imagem: "/produtos/esmaltes/esmalte-sintetico-secagem-rapida.webp", lamina: '/laminas/esmaltes/lamina-esmalte-sintetico-secagem-rapida.webp' },
        ],
    },
];

export default function TintasPage() {
    const [modalVisible, setModalVisible] = useState(false);
    const [imagemModal, setImagemModal] = useState("");
    const [nomeProduto, setProdutoNome] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [modalWidth, setModalWidth] = useState("50%");

    // Função para abrir o modal com a imagem
    const handleOpenModal = (imagem: string | undefined, nome: string) => {
        setImagemModal(""); // Limpa a imagem anterior
        setTimeout(() => {
            if (imagem) setImagemModal(imagem);
            setProdutoNome(nome);
            setModalVisible(true);
        }, 100); 
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleDownload = () => {
        if (!imagemModal) return; // Evita erro caso não tenha imagem carregada
        const link = document.createElement("a");
        link.href = imagemModal;
        link.download = imagemModal.split("/").pop() || "imagem.png"; // Nome do arquivo é o nome da imagem
        link.click();
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalWidth("99%"); // Mobile
            } else {
                setModalWidth("50%"); // Desktop
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call once to set initial width

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <Content className="content">
                <div className="container">
                    {categorias.map((categoria, index) => (
                        <div key={index} className="categoria">
                            <h2
                                className="categoria-titulo"
                                style={{
                                    backgroundColor: "#0647bd",
                                    color: "#fff",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "left",
                                    margin: 0,
                                    paddingLeft: '20px'
                                }}
                            >
                                {categoria.titulo}
                            </h2>
                            <Row gutter={[16, 16]}>
                                {categoria.produtos.map((produto: Produto, i) => (
                                    <Col key={i} xs={24} sm={12} md={6} lg={6}>
                                        <Card style={{ textAlign: "center", padding: "15px" }}>
                                            <img
                                                alt={produto.nome}
                                                src={produto.imagem}
                                                style={{
                                                    width: "120px",
                                                    height: "160px",
                                                    objectFit: "contain",
                                                    display: "block",
                                                    margin: "0 auto",
                                                }}
                                                className="produto-imagem"
                                            />
                                            <h3 style={{ fontSize: "14px", marginTop: "10px", minHeight: "40px" }}>
                                                {produto.nome}
                                            </h3>
                                            <Button
                                                type="primary"
                                                style={{
                                                    backgroundColor: "#0647bd",
                                                    borderColor: "#0647bd",
                                                    color: "#fff",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                }}
                                                onClick={() => handleOpenModal(produto.lamina, produto.nome)}
                                            >
                                                SAIBA MAIS
                                            </Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </div>
            </Content>

            <Modal
                visible={modalVisible}
                footer={null} // Remover footer para não sobrepor o botão
                onCancel={handleCloseModal}
                width="90%"
                style={{ maxWidth: modalWidth }} // Usando o valor de modalWidth
                title={
                    <Row style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            key="download"
                            onClick={handleDownload}
                            style={{ width: '150px', marginBottom: '10px' }} // Tamanho fixo de 150px
                        >
                            Baixar Imagem
                        </Button>
                        <Typography style={{ marginLeft: '20px', marginBottom: '10px', fontSize: '20px' }}>
                            {nomeProduto}
                        </Typography>
                    </Row>
                }
            >
                <img src={imagemModal} alt="Lâmina" style={{ width: '100%', height: 'auto' }} />
            </Modal>
        </Layout>
    );
};
