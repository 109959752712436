import { Button, Carousel, Typography } from "antd";
import type { CarouselRef } from "antd/es/carousel";
import { useRef } from "react";
import "./HomePage.css"; // Certifique-se de que este arquivo CSS existe
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function HomePage() {
    // Referência para o carrossel com o tipo correto importado do Ant Design
    const carouselRef = useRef<CarouselRef>(null);

    // Funções para navegar entre os slides
    const goToPrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const goToNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <>
            <div style={{ paddingTop: '30px' }}></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '2rem'
            }}>
                <Typography style={{ fontSize: '24px' }}>Bem-vindo à Durafix</Typography>
                <Typography>Sua solução completa para tintas e produtos de acabamento de alta qualidade.</Typography>
            </div>

            <div className="carousel-container">

                <Carousel autoplay ref={carouselRef}>
                    <div>
                        <img
                            src='/banners/BANNER1.webp'
                            style={{
                                objectFit: "contain",
                                display: "block",
                                margin: "0 auto",
                            }}
                            className="carousel-image produto-imagem"
                            alt="Banner 1"
                        />
                    </div>
                    <div>
                        <img
                            src='/banners/BANNER2.webp'
                            style={{
                                objectFit: "contain",
                                display: "block",
                                margin: "0 auto",
                            }}
                            className="carousel-image produto-imagem"
                            alt="Banner 2"
                        />
                    </div>
                    <div>
                        <img
                            src='/banners/BANNER3.webp'
                            style={{
                                objectFit: "contain",
                                display: "block",
                                margin: "0 auto",
                            }}
                            className="carousel-image produto-imagem"
                            alt="Banner 3"
                        />
                    </div>
                </Carousel>

                {/* Botões de navegação */}
                <Button
                    className="carousel-arrow prev-arrow"
                    icon={<LeftOutlined />}
                    onClick={goToPrev}
                    aria-label="Slide anterior"
                    type="default"
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        border: 'none',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        transition: '0.3s',
                        width: '40px',
                        height: '40px',
                        borderRadius: '0' // Deixa os cantos quadrados
                    }}
                />

                <Button
                    className="carousel-arrow next-arrow"
                    icon={<RightOutlined />}
                    onClick={goToNext}
                    aria-label="Próximo slide"
                    type="default"
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        border: 'none',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        transition: '0.3s',
                        width: '40px',
                        height: '40px',
                        borderRadius: '0' // Deixa os cantos quadrados
                    }}
                />


            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '1rem'
            }}>
                <Typography style={{ fontSize: '24px' }}>Qualidade e Confiança</Typography>
                <Typography>Na Durafix, combinamos tecnologia avançada e matérias-primas de alta qualidade para oferecer </Typography>
                <Typography>produtos que superam as expectativas mais exigentes do mercado.</Typography>
                {/* <Link to="/catalogos" style={{ textDecoration: 'none' }}>
                    <Button type="primary" style={{ marginTop: '1rem' }}>
                        Explorar Catálogos
                    </Button>
                </Link> */}

            </div>
        </>
    );
}